import {useEffect, useMemo, useReducer} from "react";
import {useSelector} from "react-redux";
import I18n from "common/lib/i18n";

export default function useI18n() {
  const locale = useSelector((state) => state.locale);
  const organization = useSelector((state) => state.current.organization);
  const i18n = useMemo(() => new I18n(locale, organization), [locale, organization]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    i18n.onLoad = () => forceUpdate();

    return () => { i18n.onLoad = null; };
  }, [i18n]);

  return i18n;
}
