import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import I18n from "common/lib/i18n";
import Markdown from "legacy/components/partials/markdown";
import Axios, {i18nAxios} from "legacy/lib/axios";

function CognitiveSection({i18n, locale, organization, profile}) {
  const {adminToken} = useParams();
  const [test, setTest] = useState(null);
  const enabled = organization.assessment_types.some(({deck_type: type}) => type === "COGNITIVE");
  const description = i18n.t("cognitive_results_description");

  useEffect(() => {
    if(!enabled) { return; }

    const axios = i18nAxios(locale.code);
    let request = Axios.CancelToken.source();

    axios.get("/api/admin/cognitive_test", {
      cancelToken: request.token,
      params: {admin_token: adminToken, profile_id: profile.id}
    }).then(({data}) => {
      request = null;

      setTest(data.test);
    });

    return () => request && request.cancel();
  }, [adminToken, profile.id]);

  if(!test) { return null; }

  const answered = test.questions.filter(({answer}) => answer);
  const correct = answered.filter(({answer}) => answer.answer_id === answer.correct_answer_id);

  return (
    <section>
      <div>
        <h2 className="heading">
          <i className="fa fa-square" />
          {i18n.t("results.cognitive.heading")}
        </h2>
      </div>
      <div className="flex-row flex-wrap">
        <div className="border-gray border-right-s flex-equal p-3">
          <h3 className="heading">
            <i className="fa fa-bars" />
            {i18n.t("results.cognitive.overview_heading")}
          </h3>
          {description ? (
            <Markdown>{description}</Markdown>
          ) : (
            <p>{i18n.t("results.cognitive.description")}</p>
          )}
        </div>
        <div className="flex-equal p-3">
          <h3 className="heading ml-2">
            <i className="fa fa-th-large" />
            {i18n.t("results.cognitive.results_heading")}
          </h3>
          <div className="flex-row">
            <div className="bg-blue flex-equal m-2 p-3 rounded text-white">
              <div>{i18n.t("results.cognitive.results.attempted")}</div>
              <div className="font-size-1">{answered.length}/{test.questions.length}</div>
            </div>
            <div className="bg-green flex-equal m-2 p-3 rounded text-white">
              <div>{i18n.t("results.cognitive.results.answered")}</div>
              <div className="font-size-1">{correct.length}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

CognitiveSection.propTypes = {
  i18n: PropTypes.instanceOf(I18n).isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string.isRequired
  }).isRequired,
  organization: PropTypes.shape({
    assessment_types: PropTypes.arrayOf(
      PropTypes.shape({
        deck_id: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  profile: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default CognitiveSection;
