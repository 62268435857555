import {combineEpics, ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, filter, ignoreElements, map, mapTo, mergeMap, tap} from "rxjs/operators";
import {GraphQL} from "traitify-widgets";
import {dig} from "common/lib/ext/object";
import traitify from "common/lib/traitify";
import axios from "legacy/lib/axios";
import {
  dependencyError,
  dependencyLoaded,
  dependencyLoading
} from "legacy/constants/app";
import {
  LOAD_ASSESSMENT,
  RELOAD_ASSESSMENT,
  REQUEST_ASSESSMENT,
  REQUEST_ASSESSMENT_FAILED,
  REQUEST_ASSESSMENT_FULFILLED,
  REQUEST_ASSESSMENT_REJECTED,
  REQUEST_ASSESSMENT_SUCCEEDED,
  STORE_ASSESSMENT,
  SHARE_ASSESSMENT,
  requestAssessment,
  requestAssessmentFailed,
  requestAssessmentFulfilled,
  requestAssessmentRejected,
  requestAssessmentSucceeded,
  storeAssessment
} from "legacy/constants/assessment";

const dependencyLoadedEpic = (action$) => action$.pipe(
  ofType(STORE_ASSESSMENT),
  mapTo("assessment"),
  map(dependencyLoaded)
);

const loadAssessmentEpic = (action$, state$) => action$.pipe(
  ofType(LOAD_ASSESSMENT),
  map(({assessmentID, surveyType}) => {
    const assessments = state$.value.assessments || [];
    const assessment = assessments.find((_assessment) => _assessment.id === assessmentID);

    return assessment ? storeAssessment(assessment) : requestAssessment(assessmentID, {surveyType});
  })
);

const loadingAssessmentEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENT),
  mapTo("assessment"),
  map(dependencyLoading)
);

const reloadAssessmentEpic = (action$, state$) => action$.pipe(
  ofType(RELOAD_ASSESSMENT),
  filter(() => state$.value.assessment),
  map(() => state$.value.assessment.id),
  map(requestAssessment)
);

const requestCognitiveAssessmentEpic = (action$, state$) => action$.pipe(
  ofType(REQUEST_ASSESSMENT),
  filter(({surveyType}) => surveyType === "COGNITIVE"),
  mergeMap(({assessmentID}) => from(
    traitify.http.post(
      GraphQL.cognitive.path,
      {
        query: GraphQL.cognitive.get,
        variables: {localeKey: state$.value.locale.code, testID: assessmentID}
      }
    )
  ).pipe(
    map((response) => response.data.cognitiveTest),
    map(requestAssessmentFulfilled),
    catchError((error) => of(requestAssessmentRejected(error.message)))
  ))
);

const assessmentData = ["blend", "instructions", "recommendation", "traits", "types"].join(",");
const assessmentURL = (assessmentID, localeKey) => (
  `/assessments/${assessmentID}?data=${assessmentData}&image_pack=linear&locale_key=${localeKey}`
);
const requestAssessmentEpic = (action$, state$) => action$.pipe(
  ofType(REQUEST_ASSESSMENT),
  filter(({surveyType}) => !surveyType || surveyType === "PERSONALITY"),
  mergeMap(({assessmentID}) => from(
    traitify.http.get(assessmentURL(assessmentID, state$.value.locale.code))
  ).pipe(
    map(requestAssessmentFulfilled),
    catchError((error) => (
      dig(error, "response", "data", "0", "error_code") === "NOT_FOUND"
        ? of(requestAssessment(assessmentID, {surveyType: "COGNITIVE"}))
        : of(requestAssessmentRejected(error.message))
    ))
  ))
);

const requestAssessmentFailedEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENT_FAILED),
  map(({error}) => dependencyError("assessment", error))
);

const requestAssessmentFulfilledEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENT_FULFILLED),
  map(({data}) => (
    data.error
      ? requestAssessmentFailed(data.error)
      : requestAssessmentSucceeded(data)))
);

const requestAssessmentRejectedEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENT_REJECTED),
  map(({error}) => error),
  map(requestAssessmentFailed)
);

const requestAssessmentSucceededEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENT_SUCCEEDED),
  map(({assessment}) => assessment),
  map(storeAssessment)
);

const shareAssessmentEpic = (action$, state$) => action$.pipe(
  ofType(SHARE_ASSESSMENT),
  tap(({provider}) => {
    const {assessment, current: {organization}} = state$.value;
    const personality = assessment.personality_blend
        || assessment.personality_types[0].personality_type;
    const assessmentType = organization.assessment_types
      .find((type) => type.deck_id === assessment.deck_id);
    const title = [
      `My ${assessmentType.name} Personality is ${personality.name}.`,
      `Find yours now with ${organization.remote.name}!`
    ].join(" ");
    const {description} = personality;
    const url = `${window.location.origin}/personality/${assessment.id}`;

    switch(provider) {
      case "facebook":
        window.FB.init({appId: "476500922525608", xfbml: true, version: "v3.0"});
        window.FB.ui({link: url, method: "feed"}, (response) => {
          (response && response.error_code)
            ? console.warn(response.error_code) // eslint-disable-line no-console
            : axios.post("/account/shared", {provider: "FB_SHARE"});
        });
        break;
      case "linkedin":
        window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}&source=Traitify`, "Traitify Sharer", "width=600, height=400");
        axios.post("/account/shared", {provider: "LINKEDIN_SHARE"});
        break;
      case "twitter":
        window.open(`http://twitter.com/share?text=${encodeURIComponent(title)}&hashtags=personality&related=traitify&url=${encodeURIComponent(url)}`, "Traitify Sharer", "width=600, height=400");
        axios.post("/account/shared", {provider: "TWITTER_SHARE"});
        break;
      default:
        break;
    }
  }),
  ignoreElements()
);

export default combineEpics(
  dependencyLoadedEpic,
  loadAssessmentEpic,
  loadingAssessmentEpic,
  reloadAssessmentEpic,
  requestAssessmentEpic,
  requestAssessmentFailedEpic,
  requestAssessmentFulfilledEpic,
  requestAssessmentRejectedEpic,
  requestAssessmentSucceededEpic,
  requestCognitiveAssessmentEpic,
  shareAssessmentEpic
);
