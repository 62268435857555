import PropTypes from "prop-types";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import useQueryParams from "common/hooks/use-query-params";
import {dig} from "common/lib/ext/object";
import traitify from "common/lib/traitify";
import {Loading} from "legacy/components/partials";
import useDisabledComponents from "legacy/lib/hooks/assessment/use-disabled-components";
import useMine from "legacy/lib/hooks/assessment/use-mine";
import useSurveyName from "legacy/lib/hooks/assessment/use-survey-name";
import FinancialRiskResults from "./financial-risk-results";
import Report from "./report";
import TypeResults from "./type-results";

function Show({
  assessment,
  assessments,
  assignLocation,
  loadAssessment,
  locale,
  organization,
  recommendation,
  updateCurrentRecommendation
}) {
  const {assessmentID} = useParams();
  const disabledComponents = useDisabledComponents();
  const mine = useMine();
  const surveyName = useSurveyName();
  const assessmentType = assessment && organization.assessment_types
    .find((type) => type.deck_id === assessment.deck_id);
  const benchmarkID = useQueryParams().get("benchmark_id") || dig(recommendation, "recommendation_id");
  const {options} = organization;

  useEffect(() => { traitify.updateLocale(locale.code); }, [locale]);
  useEffect(() => {
    const deckIDs = organization.assessment_types
      .filter((type) => type.deck_type !== "COGNITIVE")
      .map((type) => type.deck_id);

    loadAssessment(
      assessmentID || assessments.find(
        ({completed_at: completedAt, deck_id: deckID}) => completedAt && deckIDs.includes(deckID)
      ).id
    );
  }, [assessmentID]);

  useEffect(() => {
    const updateBenchmark = (state) => updateCurrentRecommendation(state.recommendation);

    return traitify.listener.on("RecommendationList.updated", updateBenchmark);
  }, []);

  useEffect(() => {
    if(!assessmentType || assessmentType.deck_type !== "COGNITIVE") { return; }

    assignLocation(`/personality/${assessmentID}/thanks`);
  }, [assessment]);

  if(!assessment) { return <Loading />; }

  const report = options.results_view || "candidate";
  const traitifyProps = traitify.props;
  const props = {
    mine,
    typeName: surveyName,
    widgetProps: {
      ...traitifyProps,
      assessmentID: assessment.id,
      options: {
        ...traitifyProps.options,
        disabledComponents,
        perspective: mine ? "firstPerson" : "thirdPerson",
        report,
        results: {benchmarkID},
        showRecommendationList: true
      }
    }
  };

  if(assessment.scoring_scale === "LIKERT_CUMULATIVE_POMP") { return <FinancialRiskResults {...props} />; }
  if(assessmentType && assessmentType.deck_type !== "DIMENSION_BASED") { return <TypeResults {...props} />; }

  return <Report {...props} />;
}

Show.defaultProps = {assessment: null, recommendation: null};
Show.propTypes = {
  assessment: PropTypes.shape({
    deck_id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    scoring_scale: PropTypes.string
  }),
  assessments: PropTypes.arrayOf(
    PropTypes.shape({
      completed_at: PropTypes.number,
      id: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  assignLocation: PropTypes.func.isRequired,
  loadAssessment: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string.isRequired,
    rails_code: PropTypes.string.isRequired
  }).isRequired,
  organization: PropTypes.shape({
    assessment_types: PropTypes.arrayOf(
      PropTypes.shape({
        deck_id: PropTypes.string.isRequired,
        deck_type: PropTypes.string.isRequired,
        i18ns: PropTypes.arrayOf(
          PropTypes.shape({
            locale_code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
          }).isRequired
        ).isRequired
      }).isRequired
    ).isRequired,
    options: PropTypes.shape({
      results_view: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  recommendation: PropTypes.shape({recommendation_id: PropTypes.string.isRequired}),
  updateCurrentRecommendation: PropTypes.func.isRequired
};

export default Show;
