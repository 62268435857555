import {combineEpics, ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, filter, map, mapTo, mergeMap} from "rxjs/operators";
import traitify from "common/lib/traitify";
import {
  LOAD_DEPENDENCY,
  dependencyError,
  dependencyLoaded,
  dependencyLoading
} from "legacy/constants/app";
import {
  LOAD_ASSESSMENTS,
  REQUEST_ASSESSMENTS,
  REQUEST_ASSESSMENTS_FAILED,
  REQUEST_ASSESSMENTS_FULFILLED,
  REQUEST_ASSESSMENTS_REJECTED,
  REQUEST_ASSESSMENTS_SUCCEEDED,
  STORE_ASSESSMENTS,
  loadAssessments,
  requestAssessments,
  requestAssessmentsFailed,
  requestAssessmentsFulfilled,
  requestAssessmentsRejected,
  requestAssessmentsSucceeded,
  storeAssessments
} from "legacy/constants/assessments";

const dependencyEpic = (action$, state$) => action$.pipe(
  ofType(LOAD_DEPENDENCY),
  filter(({dependency}) => dependency === "assessments"),
  filter(() => !state$.value.app.dependencies.loaded
    .find((dependency) => dependency === "assessments")),
  filter(() => !state$.value.app.dependencies.loading
    .find((dependency) => dependency === "assessments")),
  map(loadAssessments)
);

const dependencyLoadedEpic = (action$) => action$.pipe(
  ofType(STORE_ASSESSMENTS),
  mapTo("assessments"),
  map(dependencyLoaded)
);

const loadAssessmentsEpic = (action$, state$) => action$.pipe(
  ofType(LOAD_ASSESSMENTS),
  map(() => {
    const user = state$.value.current.user || {};

    return user.profile_uuid
      ? requestAssessments()
      : storeAssessments([]);
  })
);

const loadingAssessmentsEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENTS),
  mapTo("assessments"),
  map(dependencyLoading)
);

const assessmentsData = ["blend", "recommendation", "traits", "types"].join(",");
const assessmentsURL = (profileID, localeKey) => [
  `/profiles/${profileID}/assessments`,
  `?apply_assessment_expiration=true&data=${assessmentsData}&image_pack=linear&locale_key=${localeKey}`
].join("");
const requestAssessmentsEpic = (action$, state$) => action$.pipe(
  ofType(REQUEST_ASSESSMENTS),
  map(() => state$.value.current.user.profile_uuid),
  mergeMap((profileID) => from(
    traitify.http.get(assessmentsURL(profileID, state$.value.locale.code))
  ).pipe(
    map(requestAssessmentsFulfilled),
    catchError((error) => of(requestAssessmentsRejected(error.message)))
  ))
);

const requestAssessmentsFailedEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENTS_FAILED),
  map(({error}) => dependencyError("assessments", error))
);

const requestAssessmentsFulfilledEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENTS_FULFILLED),
  map(({data}) => (
    data.error
      ? requestAssessmentsFailed(data.error)
      : requestAssessmentsSucceeded(data)))
);

const requestAssessmentsRejectedEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENTS_REJECTED),
  map(({error}) => error),
  map(requestAssessmentsFailed)
);

const requestAssessmentsSucceededEpic = (action$) => action$.pipe(
  ofType(REQUEST_ASSESSMENTS_SUCCEEDED),
  map(({assessments}) => assessments),
  map(storeAssessments)
);

export default combineEpics(
  dependencyEpic,
  dependencyLoadedEpic,
  loadAssessmentsEpic,
  loadingAssessmentsEpic,
  requestAssessmentsEpic,
  requestAssessmentsFailedEpic,
  requestAssessmentsFulfilledEpic,
  requestAssessmentsRejectedEpic,
  requestAssessmentsSucceededEpic
);
