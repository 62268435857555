import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {GraphQL} from "traitify-widgets";
import useQueryParams from "common/hooks/use-query-params";
import traitify from "common/lib/traitify";
import Axios, {i18nAxios} from "legacy/lib/axios";

export default function useCognitiveTest({
  locale,
  organization,
  assessment,
  profile
}) {
  const [test, setTest] = useState(null);
  const {adminToken} = useParams();
  const surveyType = useQueryParams().get("survey_type");

  useEffect(() => {
    if(surveyType !== "COGNITIVE") { return; }

    traitify.http.post(
      GraphQL.cognitive.path,
      {
        query: GraphQL.cognitive.get,
        variables: {localeKey: locale.code, testID: assessment.id}
      }
    ).then(({data}) => { setTest(data.test); });
  }, [assessment]);

  useEffect(() => {
    let profileID;
    if(!profile) {
      profileID = (assessment && assessment.profile_ids) ? assessment.profile_ids[0] : null;
    } else {
      profileID = profile.id;
    }
    const enabled = organization.assessment_types.some(({deck_type: type}) => type === "COGNITIVE");

    if(!enabled) { return; }
    if(!profileID) { return; }

    const axios = i18nAxios(locale.code);
    let request = Axios.CancelToken.source();
    const userType = adminToken ? "admin" : "user";
    axios.get(`/api/${userType}/cognitive_test`, {
      cancelToken: request.token,
      params: {admin_token: adminToken, profile_id: profileID}
    }).then(({data}) => {
      request = null;
      setTest(data.test);
    });

    return () => request && request.cancel();
  }, [adminToken, profile]);

  return test;
}
