import PropTypes from "prop-types";
import {isWhite} from "common/lib/ext/color";
import I18n from "common/lib/i18n";
import logoBlack from "public/assets/images/paradox/traitify-by-paradox.svg";
import logoWhite from "public/assets/images/paradox/traitify-by-paradox-white.svg";

function Footer({i18n, organization}) {
  const footerLink1 = i18n.t("footer_link_1");
  const footerText1 = i18n.t("footer_text_1");
  const footerLink2 = i18n.t("footer_link_2");
  const footerText2 = i18n.t("footer_text_2");
  const footerLink3 = i18n.t("footer_link_3");
  const footerText3 = i18n.t("footer_text_3");

  const navbarColor = organization.options.navbar_color;
  const logo = navbarColor && !isWhite(navbarColor) ? logoWhite : logoBlack;

  return (
    <footer className="footer" role="contentinfo">
      <div className="wrapper">
        <div className="footer__row">
          <div className="footer__links">
            {(footerLink1 && footerText1) && <a href={footerLink1}>{footerText1}</a>}
            {(footerLink2 && footerText2) && <a href={footerLink2}>{footerText2}</a>}
            {(footerLink3 && footerText3) && <a href={footerLink3}>{footerText3}</a>}
          </div>
          <div className="footer__logo">
            <a href="/sessions/new" title="Return to homepage">
              <img src={logo} width="170" height="60" alt="Traitify" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  i18n: PropTypes.instanceOf(I18n).isRequired,
  organization: PropTypes.shape({
    options: PropTypes.shape({
      navbar_color: PropTypes.string
    })
  }).isRequired
};

export default Footer;
